<template>
  <div>
    <div id="loginbox">
      <img src="@/assets/img/YonghuiSync/syncpoupPost.png" />

      <van-form class="lform" ref="form" @submit="preExchange">
        <van-field
          class="lform-input key"
          placeholder="请输入兑换码"
          v-model="redeCodeNo"
          :rules="[
            {
              required: true,
              message: '请输入兑换码'
            }
          ]"
        />
        <van-button class="submit" :loading="loading" native-type="submit">
          前往核销
        </van-button>
      </van-form>

      <div class="prompt">
        <div class="prompt-title">
          <img src="@/assets/img/YonghuiSync/tips.png" alt="" />
          <span class="text">核销说明</span>
        </div>
        <div class="content">
          <p>
            1、接收验证码手机号需为本人手机号码，核销前请确保页面所有信息正确填写，核销成功后无法更换信息及退款，因个人原因导致信息填写错误以及因转增或售卖出现的售后问题，本平台概不负责。
          </p>
          <p>
            2、如遇订单量大或网络拥堵，券码出库可能有所延迟，请耐心等待，如遇兑换及使用问题，请咨询易点客服热线 ：400-662-7299（9:00-20:00）
          </p>
        </div>
      </div>
      <van-popup
        class="popReset"
        v-model="phoneVisible"
        :close-on-click-overlay="false"
        close-icon="close"
        closeable
      >
        <van-form class="lform popForm" ref="form" @submit="doSubmit">
          <van-field
            class="lform-input phone"
            placeholder="请输入您本人手机号码"
            v-model="form.phone"
            name="phone"
            :rules="[
              {
                required: true,
                validator: val => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
                message: '请填写正确的手机号'
              }
            ]"
          />
          <van-field
            class="lform-input yzm"
            placeholder="请输入验证码"
            v-model="form.code"
            :rules="[
              {
                required: true,
                message: '请输入验证码'
              }
            ]"
          >
            <template #button>
              <div class="cdown" v-if="!time" @click="sendCode">发送验证码</div>
              <van-count-down
                class="cdown"
                v-else
                :time="time"
                @finish="time = 0"
              />
            </template>
          </van-field>
          <van-button class="submit" :loading="loading" native-type="submit">
            前往核销
          </van-button>
        </van-form>
        <!-- <img class="popClose" src="@/assets/img/YonghuiSync/close.png" /> -->
      </van-popup>
    </div>
  </div>
</template>

<script>
import { syncPreCheck, syncVerify, sendVerifyCode } from '@/api/syncPop.js'
export default {
  data() {
    return {
      time: 0,
      loading: false,
      phoneVisible: false,
      redeCodeNo: undefined,
      form: {
        phone: null,
        code: null
      }
    }
  },
  methods: {
    // 兑换前校验
    async preExchange() {
      try {
        this.loading = true
        const { code, message } = await syncPreCheck({
          redeCodeNo: this.redeCodeNo
        })
        this.loading = false
        if ([0].includes(code)) {
          this.phoneVisible = true
        } else {
          this.$toast.fail({
            message: message
          })
        }
      } catch (error) {
        this.loading = false
      }
    },
    async doSubmit() {
      try {
        this.loading = true
        const { code, data, message } = await syncVerify({
          ...this.form,
          redeCodeNo: this.redeCodeNo,
          redeemCodePageType: 3,
          pageTypeMatchFlag: false
        })
        this.loading = false
        switch (code) {
          case 0:
            this.$router.push({
              path: '/syncPopList',
              query: { ...data }
            })
            break
          case 2:
            this.$router.push({
              path: '/syncPopOutStock'
            })
            break
          case 3:
            this.$router.push({
              path: '/syncPopCrowded'
            })
            break
          default:
            this.$toast.fail({
              message: message
            })
            break
        }
      } catch (error) {
        this.loading = false
      }
    },
    sendCode() {
      this.$refs.form.validate('phone').then(async () => {
        const { code, message } = await sendVerifyCode({
          ...this.form,
          redeCodeNo: this.redeCodeNo,
          domain: window.location.hostname
        })
        if (code === 0) {
          this.time = 60000
        } else {
          this.$toast.fail({
            message: message
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/YonghuiSync.less';
.popReset {
  border-radius: 30px !important;
  // position: relative;
  .popForm {
    width: 85vw;
    padding: 100px 40px 60px !important;
    .submit {
      margin-top: 20px;
      border-radius: 44px;
    }
  }
  .popClose {
    position: fixed;
    bottom: -100px;
    width: 62px;
  }
}
</style>

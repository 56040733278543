var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"loginbox"}},[_c('img',{attrs:{"src":require("@/assets/img/YonghuiSync/syncpoupPost.png")}}),_c('van-form',{ref:"form",staticClass:"lform",on:{"submit":_vm.preExchange}},[_c('van-field',{staticClass:"lform-input key",attrs:{"placeholder":"请输入兑换码","rules":[
          {
            required: true,
            message: '请输入兑换码'
          }
        ]},model:{value:(_vm.redeCodeNo),callback:function ($$v) {_vm.redeCodeNo=$$v},expression:"redeCodeNo"}}),_c('van-button',{staticClass:"submit",attrs:{"loading":_vm.loading,"native-type":"submit"}},[_vm._v(" 前往核销 ")])],1),_vm._m(0),_c('van-popup',{staticClass:"popReset",attrs:{"close-on-click-overlay":false,"close-icon":"close","closeable":""},model:{value:(_vm.phoneVisible),callback:function ($$v) {_vm.phoneVisible=$$v},expression:"phoneVisible"}},[_c('van-form',{ref:"form",staticClass:"lform popForm",on:{"submit":_vm.doSubmit}},[_c('van-field',{staticClass:"lform-input phone",attrs:{"placeholder":"请输入您本人手机号码","name":"phone","rules":[
            {
              required: true,
              validator: val => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
              message: '请填写正确的手机号'
            }
          ]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{staticClass:"lform-input yzm",attrs:{"placeholder":"请输入验证码","rules":[
            {
              required: true,
              message: '请输入验证码'
            }
          ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [(!_vm.time)?_c('div',{staticClass:"cdown",on:{"click":_vm.sendCode}},[_vm._v("发送验证码")]):_c('van-count-down',{staticClass:"cdown",attrs:{"time":_vm.time},on:{"finish":function($event){_vm.time = 0}}})]},proxy:true}]),model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('van-button',{staticClass:"submit",attrs:{"loading":_vm.loading,"native-type":"submit"}},[_vm._v(" 前往核销 ")])],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prompt"},[_c('div',{staticClass:"prompt-title"},[_c('img',{attrs:{"src":require("@/assets/img/YonghuiSync/tips.png"),"alt":""}}),_c('span',{staticClass:"text"},[_vm._v("核销说明")])]),_c('div',{staticClass:"content"},[_c('p',[_vm._v(" 1、接收验证码手机号需为本人手机号码，核销前请确保页面所有信息正确填写，核销成功后无法更换信息及退款，因个人原因导致信息填写错误以及因转增或售卖出现的售后问题，本平台概不负责。 ")]),_c('p',[_vm._v(" 2、如遇订单量大或网络拥堵，券码出库可能有所延迟，请耐心等待，如遇兑换及使用问题，请咨询易点客服热线 ：400-662-7299（9:00-20:00） ")])])])
}]

export { render, staticRenderFns }